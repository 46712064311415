export enum PermissionConsts {
    // Pages

    Pages_Users = 'Pages.Users',
    Pages_Persons = 'Pages.Persons',
    Pages_Partners = 'Pages.Partners',
    Pages_CoWorkers = 'Pages.CoWorkers',

    // User

    User_Create = 'User.Create',
    User_Detail = 'User.Detail',
    User_Edit = 'User.Edit',
    User_Delete = 'User.Delete',
    User_Get = 'User.Get',
    User_RequestEmailConfirmation = 'User.RequestEmailConfirmation',
    User_GetAll = 'User.GetAll',
    User_ForceResetPassword = 'User.ForceResetPassword',
    User_Deactivate = 'User.Deactivate',
    User_UnlockLogin = 'User.UnlockLogin',

    // Files

    File_List = 'File.List',
    File_Detail = 'File.Detail',
    File_Create = 'File.Create',
    File_Delete = 'File.Delete',

    // Contact Person

    ContactPerson_Create = 'ContactPerson.Create',
    ContactPerson_Update = 'ContactPerson.Update',
    ContactPerson_Get = 'ContactPerson.Get',
    ContactPerson_GetAll = 'ContactPerson.GetAll',
    ContactPerson_Delete = 'ContactPerson.Delete',

    // Partner User

    PartnerUser_Create = 'ContactPerson.Create',
    PartnerUser_Get = 'ContactPerson.Get',
    PartnerUser_GetAll = 'ContactPerson.GetAll',
    PartnerUser_Delete = 'ContactPerson.Delete',

    // Residence Info

    ResidenceInfo_Create = 'ResidenceInfo.Create',
    ResidenceInfo_Update = 'ResidenceInfo.Update',
    ResidenceInfo_Get = 'ResidenceInfo.Get',
    ResidenceInfo_GetAll = 'ResidenceInfo.GetAll',
    ResidenceInfo_Delete = 'ResidenceInfo.Delete',

    // Personal Info

    PersonalInfo_Create = 'PersonalInfo.Create',
    PersonalInfo_Update = 'PersonalInfo.Update',
    PersonalInfo_Get = 'PersonalInfo.Get',
    PersonalInfo_GetAll = 'PersonalInfo.GetAll',
    PersonalInfo_Delete = 'PersonalInfo.Delete',

    // Selection

    Selection_Create = 'Selection.Create',
    Selection_Get = 'Selection.Get',
    Selection_GetAll = 'Selection.GetAll',
    Selection_Update = 'Selection.Update',
    Selection_Delete = 'Selection.Delete',
    Selection_EditPartner = 'Selection.EditPartner',
    Selection_AdvanceFilters = 'Selection.AdvancedFilters',

    //Operative Units

    OperativeUnit_Create = 'OperativeUnit.Create',
    OperativeUnit_Get = 'OperativeUnit.Get',
    OperativeUnit_GetAll = 'OperativeUnit.GetAll',
    OperativeUnit_Update = 'OperativeUnit.Update',
    OperativeUnit_Delete = 'OperativeUnit.Delete',

    //Planning SIL file
    PlanningSilFile_Create = 'PlanningSilFile.Create',
    PlanningSilFile_Edit = 'PlanningSilFile.Edit',
    PlanningSilFile_Delete = 'PlanningSilFile.Delete',

    //Profile

    Admin_Profile = 'Admin_Profile',
    Solco_Profile = 'Solco_Profile',
    Person_Profile = 'Person_Profile',
    Partner_Profile = 'Partner_Profile',

    //Candidate

    Candidates_List = 'Candidates.List',

    //Matching

    User_SelectionMatching = 'User.SelectionMatching',
}
